.body {
   width: 100%;
   height: calc(100vh - 31px);
   overflow-x: hidden;
   overflow-y: auto;
   display: flex;
}

.content {
   padding: 3px 10px;;
}