@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body,
html,
#app {
   padding: 0;
   margin: 0;
}
* {
   font-family: 'PT Sans', sans-serif;
}
