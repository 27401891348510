.container {
   
}

.product_toggle_lists {
   display: flex;
   align-items: center;
   gap: 10px;
}

.product_item {
   color: #0078B9 !important;   
   cursor: pointer;
}


.product_item[active='1'] {
   text-decoration: underline
}