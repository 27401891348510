.container {
   width: 150px;
   height: calc(100vh - 45px);
   overflow-y: auto;
   overflow-x: hidden;
   position: relative;
   padding: 6px 10px;
   border-right: 1px solid rgba(0,0,0,0.20);
}

.links {
   display: flex;
   flex-direction: column;
}

.link {
   text-decoration: none !important;
   color: #0078B9 !important;
}

.link[active='1'] {
   text-decoration: underline !important;
}