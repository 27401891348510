.container {
   display: flex;
   gap: 10px;
}

.type {
   width: 99px;
   height: 27px;
}


.add_note {
   flex: 1;
}

.add {
   width: 40px;
   height: 27px;
}