.container {
   margin-top: 10px;
   width: 500px;
   display: flex;
   flex-direction: column;
   gap: 8px;
}

.container * {
   width: 100%;
}
