.container {
   width: 100%;
   height: 30px;
   background: #fff;
   border-bottom: 1px solid rgba(0,0,0,0.22);
}


.app {
   height: 30px;
   width: fit-content;
   display: flex;
   align-items: center;
   margin-left: 10px;
}

.name {  
   font-size: 16px;
}