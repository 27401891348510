.data_table {
   width: 300px;
   max-width: 100%;
   border: 1px solid rgba(0,0,0,0.53);
   border-radius: 0px;
   margin-top: 10px;
}

td {
   white-space: nowrap;
}


table, th, td {
   border: 1px solid black;
   border-collapse: collapse;
   padding: 3px 6px;
 }

 .action {
   display: flex;
   align-items: center;
   gap: 5px;
 }