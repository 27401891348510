.container {
   width: 100%;
   height: 30px;
   background: #fff;
   position: relative;
}

.bar_container {
   width: 100%;
   position: relative;
   height: 10px;
   border-radius: 5px;
   border: 1px solid rgba(0,0,0,0.20);
   overflow: hidden;
}

.bar {
   background: #0078B9;
   height: 10px;
   transition: 0.2s ease-in-out width;
}

.footer {
   display: flex;
   width: 100%;
}

.percentage {  
   font-size: 13px;
} 

.progress {
   white-space: nowrap;
   width: 100%;
   display: flex;
   margin-left:auto;
   margin-right: 0;
   align-items: center;
   width: fit-content;
   font-size: 13px;
}